/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'
import * as signalR from '@microsoft/signalr'
import { IUserInfo } from '@rsmus/ecp-userservice'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { OidcIdentityContext, getAccessToken } from '@rsmus/react-auth'
import { useIdleTimer } from 'react-idle-timer'
import { Box, Button } from '@mui/material'
import CountdownTimer from '../CountdownTimer'
import { Modal } from '../overlay'
import TimeIcon from '../icons/TimeIcon'
import { useAppSelector, useUser } from '../../utils/hooks'
import { setBrowserTimeout } from '../../store/configuration/configurationSlice'
import {
  SignalRMethods,
  TargetingFeatureFlag,
} from '../../utils/constants/constants'
import {
  calculateIdleMinutesUntilIdle,
  idleMinutesUntilTimeout,
} from './sessionManager.service'
import {
  setReadyState,
  setUserInfo,
  getUserInfo,
  setCemFeatures,
  setTargetedFeatureFlags,
} from '../../store/userInfo/userInfoSlice'
import {
  BROWSER_TIMEOUT,
  COMMUNICATIONSERVICE_BASE_URL,
} from '../../envVariables'

import {
  setDeleteNotificationId,
  setNewNotificationCount,
} from '../../store/notification/notificationSlice'
import { RootState } from '../../store'
import api from '../../api'

const getToken = async () => {
  const result = await getAccessToken()
  return result || ''
}

const SessionManager = () => {
  const [userIsIdle, setUserIsIdle] = useState(false)
  const isEmployee =
    useAppSelector((state) => state.userInfo.UserInfo?.isEmployee) ?? false
  const browserTimeoutTotal: number =
    BROWSER_TIMEOUT ??
    useAppSelector((state) => state.configurations.browserTimeout)
  const inactivityWarningMinutes = calculateIdleMinutesUntilIdle(
    browserTimeoutTotal,
    idleMinutesUntilTimeout,
  )
  const { logout } = React.useContext(OidcIdentityContext)
  const userInfo = useSelector(getUserInfo)
  const [screenReaderMessage, setScreenReaderMessage] = useState('')

  const idleLogout = () => {
    logout(true)
    return undefined
  }

  const { t } = useTranslation()

  const dispatch = useDispatch()

  const getBrowserTimeoutData = () => {
    api.user.configuration_GetBrowserInactivityTimeout().then((response) => {
      dispatch(setBrowserTimeout(response.data))
    })
  }

  const requestTimeout = 45000
  const getUserInfoData = async () => {
    try {
      const [userResponse, userFeaturesResponse, targetedFeatureFlagsResponse] =
        await Promise.allSettled([
          api.user.userInfo_GetUserInfo(),
          api.user.userInfo_GetUserFeatures(requestTimeout),
          api.user.featureFlag_GetFeatureFlags(true, false),
        ])

      if (userResponse.status === 'rejected') {
        throw new Error('User info request failed')
      }
      if (userFeaturesResponse.status === 'rejected') {
        throw new Error('User features request failed')
      }
      if (targetedFeatureFlagsResponse.status === 'rejected') {
        throw new Error('Targeted feature flags request failed')
      }

      dispatch(setUserInfo(userResponse.value.data || ({} as IUserInfo)))
      dispatch(setCemFeatures(userFeaturesResponse.value.data || []))
      dispatch(
        setTargetedFeatureFlags(
          targetedFeatureFlagsResponse.value.data
            ?.filter((x) => x.isEnabled)
            .map((x) => x.name as TargetingFeatureFlag) ?? [],
        ),
      )
      dispatch(setReadyState(true))
    } catch (e) {
      try {
        const uResponse = await api.user.userInfo_GetUserInfo()
        dispatch(setUserInfo(uResponse.data || ({} as IUserInfo)))
        dispatch(setReadyState(true))
      } catch (err) {
        const user = useUser()
        if (user?.profile) {
          const { FirstName, LastName, email, rsmuid } = user.profile
          dispatch(
            setUserInfo({
              firstName: FirstName,
              lastName: LastName,
              userId: rsmuid,
              isEmployee: false,
              emailAddress: email,
              userType: undefined,
            }),
          )
        } else {
          dispatch(
            setUserInfo({
              firstName: undefined,
              lastName: undefined,
              userId: undefined,
              isEmployee: false, // Provide appropriate default or fetched values
              emailAddress: undefined,
              userType: undefined,
            }),
          )
        }
        dispatch(setReadyState(false))
      }
    }
  }

  const initSignalR = async () => {
    try {
      const connection = new signalR.HubConnectionBuilder()
        .withUrl(`${COMMUNICATIONSERVICE_BASE_URL}/hub`, {
          accessTokenFactory: getToken,
          withCredentials: true,
        })
        .withAutomaticReconnect()
        .build()

      const notificationsNewCount: SignalRMethods = 'NotificationsNewCount'
      connection.on(notificationsNewCount, (data) => {
        dispatch(setNewNotificationCount(data))
      })
      const notificationsDelete: SignalRMethods = 'NotificationsDelete'
      connection.on(notificationsDelete, (data) => {
        dispatch(setDeleteNotificationId(data))
      })

      const startSignalR = () => {
        try {
          connection.start()
        } catch (err) {
          setTimeout(() => startSignalR(), 5000)
        }
      }
      startSignalR()
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
    }
  }

  const handleOnIdle = () => {
    // business logic, only non-employees are logged out
    if (!isEmployee) {
      setUserIsIdle(true)
    }
  }

  const handleCloseIdleModal = () => {
    setUserIsIdle(false)
  }

  useEffect(() => {
    getBrowserTimeoutData()
    getUserInfoData()
    initSignalR()
  }, [])

  const idleTimer = useIdleTimer({
    timeout: inactivityWarningMinutes * 1000 * 60,
    onIdle: handleOnIdle,
    debounce: 250,
  })

  return (
    <Box id="session-manager-component">
      <Modal isOpen={userIsIdle} closeHandler={handleCloseIdleModal}>
        <Box
          className="Modal-Body text-center"
          id="session-manager-timeout-warning-modal">
          <Box>
            <TimeIcon classNames="block m-auto w-[120px] h-[120px] mb-[8px]" />
          </Box>
          <Box className="my-[8px] text-[36px] text-[#515356] leading-[40px]">
            <CountdownTimer
              minutesRemaining={idleMinutesUntilTimeout}
              onComplete={idleLogout}
              on10MinWarning={() => {
                setScreenReaderMessage(t('SessionManager.Exp10Min'))
                return undefined
              }}
              on5MinWarning={() => {
                setScreenReaderMessage(t('SessionManager.Exp5Min'))
                return undefined
              }}
              on1MinWarning={() => {
                setScreenReaderMessage(t('SessionManager.Exp1Min'))
                return undefined
              }}
              on30secWarning={() => {
                setScreenReaderMessage(t('SessionManager.Exp30Sec'))
                return undefined
              }}
              on10secWarning={() => {
                setScreenReaderMessage(t('SessionManager.Exp10Sec'))
                return undefined
              }}
              on5secWarning={() => {
                setScreenReaderMessage(t('SessionManager.Exp5Sec'))
                return undefined
              }}
              on1secWarning={() => {
                setScreenReaderMessage(t('SessionManager.Exp1Sec'))
                return undefined
              }}
            />
            <Box className="sr-only" aria-atomic="true" aria-live="assertive">
              {screenReaderMessage}
            </Box>
          </Box>
          <Box
            id="ecp_top_modal_title"
            component="h1"
            className="my-[8px] text-[36px] text-gray leading-[40px]">
            {t('SessionTimeout')}
          </Box>
          <Box className="my-[8px] text-[16px] text-gray leading-[24px]">
            {t('SessionTimeoutExplination')}
          </Box>
          <Button
            onClick={handleCloseIdleModal}
            variant="contained"
            color="primary"
            className="my-[18px]  tablet:w-[inherit]  mobile:w-full text-[18px] ">
            {t('Reset')}
          </Button>
        </Box>
      </Modal>
    </Box>
  )
}

export default SessionManager
