import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton,
  Typography,
  Box,
} from '@mui/material'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import CloseIcon from '@mui/icons-material/Close'
import { useSelector, useDispatch } from 'react-redux'
import WarningIcon from 'src/components/icons/WarningIcon/WarningIcon'
import {
  getPaymentSelectedFilters,
  getPaymentCounts,
  setPaymentsExportStatus,
} from 'src/store/invoices/paymentSelectedFiltersSlice'
import { toPaymentSearchFilter } from 'src/utils/helpers/filterAdapter'
import ResponsiveModal from '../../../rsmCoreComponents/components/ResponsiveModal'
import { REACT_APP_EXPORT_MAX_LIMIT } from '../../../envVariables'
import { Styles } from '../../../types'
import api from '../../../api'
import tokens from '../../../styles/tokens.json'

const styles: Styles = {
  Dialog: () => ({
    '& .MuiPaper-root': {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      outline: 'none',
      Width: { xs: '100%', md: '32rem' },
      Height: { xs: '100%', md: '41rem' },
      width: '100%',
    },
  }),
  DialogTitle: () => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: '0.625rem',
  }),
  ButtonGroup: () => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1rem',
    paddingBottom: '1.2rem',
    paddingTop: '1rem',
  }),
}

const warningContainerStyle = {
  width: '100%',
  background: tokens.colors.rsmRed.warning,
  display: 'flex',
  justifyContent: 'center',
  padding: '0.75rem 1rem',
}

const warningInnerBoxStyle = {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  padding: '0.75rem 1rem',
  gap: '0.75rem',
}

const warningIconStyle = {
  fontSize: '1.5rem',
  color: tokens.colors.warning.main,
  flexShrink: 0,
}

const warningTextStyle = {
  color: tokens.colors.rsmText.primary,
  fontWeight: 400,
  lineHeight: 1.5,
  wordBreak: 'break-word',
  flex: 1,
  fontFamily: tokens.type.fontFamilies.preloMedium,
}

interface ExportModalProps {
  open: boolean
  onClose: () => void
}

const PaymentHistoryExportModal = ({ open, onClose }: ExportModalProps) => {
  const getDateStamp = (): string => {
    const now = new Date()
    const month = String(now.getMonth() + 1).padStart(2, '0')
    const day = String(now.getDate()).padStart(2, '0')
    const year = String(now.getFullYear()).slice(-2)
    return `${month}.${day}.${year}`
  }

  const dispatch = useDispatch()
  const [isExportEnabled, setIsExportEnabled] = useState<boolean>(true)
  const [selectedOption, setSelectedOption] = useState<
    'filtered' | 'entire' | null
  >(null)

  const paymentFilters = useSelector(getPaymentSelectedFilters)
  const { filteredPayments, totalPayments } = useSelector(getPaymentCounts)

  const handleExport = async () => {
    try {
      const applyFilter = selectedOption === 'filtered'
      const filterPayload = toPaymentSearchFilter(paymentFilters)

      const response = await api.finance.invoiceDocument_ExportPaymentsToCsv(
        applyFilter,
        filterPayload,
      )

      if (response?.data) {
        const blob = new Blob([response.data], {
          type: 'text/csv;charset=utf-8;',
        })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute(
          'download',
          `Payment History Summary ${getDateStamp()}.csv`,
        )
        document.body.appendChild(link)
        link.click()
        link.remove()

        dispatch(setPaymentsExportStatus({ isSuccess: false, showAlert: true }))
      } else {
        dispatch(setPaymentsExportStatus({ isSuccess: false, showAlert: true }))
      }
    } catch (error) {
      dispatch(setPaymentsExportStatus({ isSuccess: false, showAlert: true }))
      console.error('Export failed:', error)
    }
  }

  useEffect(() => {
    if (!selectedOption) {
      setIsExportEnabled(false)
      return
    }

    if (
      (selectedOption === 'filtered' &&
        filteredPayments > REACT_APP_EXPORT_MAX_LIMIT) ||
      (selectedOption === 'entire' &&
        totalPayments > REACT_APP_EXPORT_MAX_LIMIT)
    ) {
      setIsExportEnabled(false)
    } else {
      setIsExportEnabled(true)
    }
  }, [selectedOption, filteredPayments, totalPayments])

  useEffect(() => {
    if (filteredPayments === 0) {
      setSelectedOption('entire')
    }
  }, [filteredPayments])

  const { t } = useTranslation()

  return (
    <ResponsiveModal
      open={open}
      onClose={onClose}
      aria-labelledby="export-Payment-history"
      sx={styles.Dialog}
      desktopMaxHeight="38rem"
      desktopMaxWidth="30rem"
      removeHeaderSpacing
      removeCloseButton
      data-testid="Lbl_Invoicing_PaymentHistoryExport_Modal">
      <Box tabIndex={-1} role="document">
        <IconButton
          onClick={onClose}
          aria-label="Close"
          sx={{ position: 'absolute', top: '0.625rem', right: '0.625rem' }}>
          <CloseIcon sx={{ fontSize: '1.5rem' }} />
        </IconButton>

        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 1 }}>
          <FileDownloadOutlinedIcon sx={{ fontSize: '3.5rem' }} />
        </Box>
        <Typography
          id="export-radio-group-label"
          variant="h5"
          component="h1"
          role="heading"
          sx={{
            fontWeight: 'bold',
            textAlign: 'center',
            marginTop: '0.3rem',
          }}>
          {t('Invoicing.PaymentHistoryExport.ExportPaymentHistory')}
        </Typography>

        <Box sx={{ p: '1.3rem 1.9rem', textAlign: 'center' }}>
          <RadioGroup
            value={selectedOption}
            onChange={(e) =>
              setSelectedOption(e.target.value as 'filtered' | 'entire')
            }
            aria-labelledby="export-radio-group-label">
            {filteredPayments > 0 && filteredPayments !== totalPayments ? (
              <>
                <FormControlLabel
                  sx={{ padding: '0.3rem 0', marginBottom: '0.3' }}
                  value="filtered"
                  control={<Radio />}
                  label={
                    <Typography variant="body1">
                      {t(
                        'Invoicing.PaymentHistoryExport.ExportFilteredResults',
                      )}{' '}
                      ({filteredPayments} results)
                    </Typography>
                  }
                />
                <FormControlLabel
                  sx={{ padding: '0.3rem 0', marginTop: '0.3rem' }}
                  value="entire"
                  control={<Radio />}
                  label={
                    <Typography variant="body1">
                      {t('Invoicing.PaymentHistoryExport.ExportEntireRecord')} (
                      {totalPayments} results)
                    </Typography>
                  }
                />
              </>
            ) : (
              <FormControlLabel
                sx={{ padding: '0.3rem 0', marginTop: '0.3rem' }}
                value="entire"
                control={<Radio />}
                label={
                  <Typography variant="body1">
                    {t('Invoicing.PaymentHistoryExport.ExportEntireRecord')} (
                    {totalPayments} results)
                  </Typography>
                }
              />
            )}
          </RadioGroup>
        </Box>

        <Box
          sx={{
            minHeight: '4.5rem',
            transition: 'all 0.3s ease-in-out',
            opacity:
              (selectedOption === 'filtered' &&
                filteredPayments > REACT_APP_EXPORT_MAX_LIMIT) ||
              (selectedOption === 'entire' &&
                totalPayments > REACT_APP_EXPORT_MAX_LIMIT)
                ? 1
                : 0,
            visibility:
              (selectedOption === 'filtered' &&
                filteredPayments > REACT_APP_EXPORT_MAX_LIMIT) ||
              (selectedOption === 'entire' &&
                totalPayments > REACT_APP_EXPORT_MAX_LIMIT)
                ? 'visible'
                : 'hidden',
          }}>
          <Box sx={warningContainerStyle}>
            <Box sx={warningInnerBoxStyle}>
              <WarningIcon sx={warningIconStyle} />
              <Typography variant="body2" sx={warningTextStyle}>
                {t('Invoicing.PaymentHistoryExport.ExportLimitWarning', {
                  exportLimit: REACT_APP_EXPORT_MAX_LIMIT,
                })}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={styles.ButtonGroup}>
          <Button
            variant="outlined"
            onClick={onClose}
            data-testid="Btn_Invoicing_PaymentHistoryExport_Cancel">
            {t('Invoicing.PaymentHistoryExport.Cancel')}
          </Button>
          <Button
            variant="contained"
            onClick={handleExport}
            disabled={!isExportEnabled}
            data-testid="Btn_Invoicing_PaymentHistoryExport_Export">
            {t('Invoicing.PaymentHistoryExport.exportToCSV')}
          </Button>
        </Box>
      </Box>
    </ResponsiveModal>
  )
}

export default PaymentHistoryExportModal
